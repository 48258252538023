<template>
  <v-container>
    <BaseTitlePage class="mb-0" />

    <BaseSearchPage title="Consultar e filtrar por clientes" class="mt-0">
      <template v-slot:form>
        <v-form
          @submit.prevent="search"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseCustomersAutocomplete
                label="Cliente"
                hide-details
                required
                :rules="[required]"
                v-model="filter.customerId"
              />
            </v-col>

            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseButton
                height="40"
                color="primary"
                title="Buscar"
                type="submit"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de contratos">
      <template v-slot:table>
        <v-data-table
          dense
          show-select
          disable-sort
          item-key="contractId"
          v-model="selected"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.customerDocument`]="{ item }">
            {{ formatCnpj(item.customerDocument) }}
          </template>
          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-row dense>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
        <BaseButton color="primary" outlined title="Voltar" @click="back" />
        <BaseButton
          color="primary"
          title="Iniciar implantação"
          :disabled="selected.length === 0"
          @click="init"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatCnpj, formatDate } from '@/helpers/formatting';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    items: [],
    totalRecords: 0,
    options: {},
    selected: [],
    filter: {
      customerId: null
    },
    headers: [
      { text: 'Nº Contrato', value: 'contractCode' },
      { text: 'Produto', value: 'productType' },
      { text: 'Operadora / Seguradora', value: 'insuranceCompanyName' },
      { text: 'Vigência', value: 'effectiveDate' }
    ]
  }),

  mounted() {
    if (this.$refs && this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  },

  methods: {
    formatCnpj,
    formatDate,

    back() {
      this.$router.push({ name: 'implantacoes' });
    },

    async search() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const { page, itemsPerPage } = this.options;
      const { customerId } = this.filter;

      if (customerId) {
        try {
          const implementationService = new ImplementationService();
          const { status, data } =
            await implementationService.availableContracts({
              page,
              take: itemsPerPage,
              customerId
            });

          if (status === 200) {
            this.items = data.results;
            this.totalRecords = data.totalRecords;
          } else {
            this.items = [];
            this.totalRecords = 0;
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async init() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.create({
          customerId: this.filter.customerId,
          contracts: this.selected.map(x => x.contractId)
        });

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');

          this.$router.push({
            name: 'implantacoes-edit',
            params: {
              id: data.implementationId
            }
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
