var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseTitlePage',{staticClass:"mb-0"}),_c('BaseSearchPage',{staticClass:"mt-0",attrs:{"title":"Consultar e filtrar por clientes"},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('BaseCustomersAutocomplete',{attrs:{"label":"Cliente","hide-details":"","required":"","rules":[_vm.required]},model:{value:(_vm.filter.customerId),callback:function ($$v) {_vm.$set(_vm.filter, "customerId", $$v)},expression:"filter.customerId"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"6"}},[_c('BaseButton',{attrs:{"height":"40","color":"primary","title":"Buscar","type":"submit","disabled":!_vm.valid}})],1)],1)],1)]},proxy:true}])}),_c('BaseTableList',{attrs:{"title":"Listagem de contratos"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","show-select":"","disable-sort":"","item-key":"contractId","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.customerDocument`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCnpj(item.customerDocument))+" ")]}},{key:`item.effectiveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.effectiveDate))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]},proxy:true}])}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('BaseButton',{attrs:{"color":"primary","outlined":"","title":"Voltar"},on:{"click":_vm.back}}),_c('BaseButton',{attrs:{"color":"primary","title":"Iniciar implantação","disabled":_vm.selected.length === 0},on:{"click":_vm.init}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }